// src/components/Footer.jsx
import React from "react";
import { useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";

const Footer = () => {
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);
  const terms = useSelector(selectTerms);

  return (
    <div className="footer">
      {isAllowedDomain ? (
        <div className="footer_logo_global" />
      ) : (
        <>
          <p className="footer_text">
            {/* This service is provided by */}
            {terms.footer_provided_by}
          </p>
          <div className="footer_logo" />
        </>
      )}
    </div>
  );
};

export default Footer;
