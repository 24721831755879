// src/components/InnerPagesComponents.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page3Global from './Page3Global';
import Page4 from './Page4';
import Page4Global from './Page4Global';
import Page1Global from './Page1Global';
import Page2Global from './Page2Global';

const InnerPagesComponents = () => {
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);
  const index = useSelector((state) => state.navigation.componentKey);
  // const components = [<Page1 key={0} />, <Page2 key={1} />, <Page3 key={2} />, <Page4 key={3} />];
  const components = isAllowedDomain ? [<Page1Global key={0} />, <Page2Global key={1} />, <Page3Global key={2} />, <Page4Global key={3} />] 
                                     : [<Page1 key={0} />, <Page2 key={1} />, <Page3 key={2} />, <Page4 key={3} />];
  return components[index];
};

export default InnerPagesComponents;
