// src/components/Header.jsx
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTerms } from "../features/terms/termsSlice";
import { toggleTheme } from "../features/theme/themeSlice";
import ThemeToggle from "./ThemeToggle";

const Header = ({ index, onBackClick }) => {
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);
  const theme = useSelector((state) => state.theme);
  const terms = useSelector(selectTerms);
  const dispatch = useDispatch();

  const titles = {
    0: terms.header_title_buy_usdp_0,
    1: terms.header_title_buy_usdp_1,
    2: terms.header_title_buy_usdp_2,
    3: terms.header_title_buy_usdp_3,
  };
  
  const titlesGlobal = {
    0: terms.header_title_global_buy_usdp,
    1: terms.header_title_global_buy_usdp,
    2: terms.header_title_global_buy_usdp,
    3: terms.header_title_global_buy_usdp,
  };

  const title = (isAllowedDomain ? titlesGlobal : titles)[index] || titles[0];
  const preventPrevious = [0, 3];

  return (
    <div className={`header ${theme}`}>
      {!preventPrevious.includes(index) && (
        <div className="back" onClick={onBackClick} />
      )}
      <ThemeToggle theme={theme} toggleTheme={() => dispatch(toggleTheme())} />
      <div className="header_title">{title}</div>
      <div className="logo" />
    </div>
  );
};

export default Header;
