// src/components/Page1.jsx
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCoin } from "../features/exchange/exchangeSlice";
import { moveToPage } from "../features/navigation/navigationSlice";
import Coin from "./Coin";
import { selectTerms } from "../features/terms/termsSlice";

const Page1 = () => {
  const coins = useSelector((state) => state.coins.coins);
  const error = useSelector((state) => state.coins.error);
  const dispatch = useDispatch();
  const terms = useSelector(selectTerms);

  const handleClick = useCallback(
    (selectedCoin) => {
      dispatch(setSelectedCoin(selectedCoin));
      dispatch(moveToPage(1));
    },
    [dispatch]
  );

  if (error) {
    return (
      <div className="error">
        {/* There was an error */}
        {terms.page1_error_message}: {error}
      </div>
    );
  }

  if (!coins.length) {
    return (
      <div className="loading">
        {/* Loading... */}
        {terms.page1_loading}
      </div>
    );
  }

  return (
    <div className="page1">
      {/* Title Section */}
      <h1 className="page1-title">
        {/* Bridge with 0% fees */}
        {terms.page1_bridge_title}
      </h1>
      {/* Coins Section */}
      {coins.map((coin, index) => (
        <Coin
          key={coin.id}
          index={index}
          coin={coin}
          onClick={() => handleClick(coin)}
        />
      ))}
    </div>
  );
};

export default Page1;
