// src/features/transaction/transactionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    depositAddress: null,
    network: null,
    wsData: null,
    status: 1,
  },
  reducers: {
    setDepositAddress: (state, action) => {
      state.depositAddress = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setWsData: (state, action) => {
      state.wsData = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setDepositAddress, setNetwork, setWsData, updateStatus } =
  transactionSlice.actions;
export default transactionSlice.reducer;
