// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import coinsReducer from "../features/coins/coinsSlice";
import exchangeReducer from "../features/exchange/exchangeSlice";
import loadingReducer from "../features/loading/loadingSlice";
import navigationReducer from "../features/navigation/navigationSlice";
import paymentReducer from "../features/payment/paymentSlice";
import themeReducer from "../features/theme/themeSlice";
import transactionReducer from "../features/transaction/transactionSlice";
import transactionStatusReducer from "../features/transactionStatus/transactionStatusSlice";
import hostReducer from "../features/host/hostSlice";
import iframeReducer from "../features/iframe/iframeSlice";
import termsReducer from "../features/terms/termsSlice";

const store = configureStore({
  reducer: {
    coins: coinsReducer,
    exchange: exchangeReducer,
    loading: loadingReducer,
    navigation: navigationReducer,
    payment: paymentReducer,
    theme: themeReducer,
    transaction: transactionReducer,
    transactionStatus: transactionStatusReducer,
    host: hostReducer,
    iframe: iframeReducer,
    terms: termsReducer,
  },
});

export default store;
