// src/components/Page2Global.jsx
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateUSDP,
  setExchangeRate,
  setFee,
} from "../features/exchange/exchangeSlice";
import {
  setIsLoading,
  setRingStatuses,
} from "../features/loading/loadingSlice";
import { moveToPage } from "../features/navigation/navigationSlice";
import {
  setDepositAddress,
  setNetwork,
  updateStatus,
} from "../features/transaction/transactionSlice";
import { fetchData } from "../utils/fetchData";
import { endpoints } from "../utils/fetchUrls";
import ExchangeRate from "./ExchangeRate";
import InputGroup from "./InputGroup";
import SingleTransactionStatus from "./SingleTransactionStatus";

const Page2Global = () => {
  const dispatch = useDispatch();
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const amount = useSelector((state) => state.exchange.amount);
  const exchangeRate = useSelector((state) => state.exchange.exchangeRate);
  const fee = useSelector((state) => state.exchange.fee);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);

  const userMagicAddress = useSelector((state) => state.host?.userMagicAddress);

  useEffect(() => {
    if (!selectedCoin) return;

    const getExchangeRate = () => {
      fetchData(`${endpoints.getExchangeRate}/${selectedCoin.nexusCurrencyId}`)
        .then((data) => {
          dispatch(setExchangeRate(data.data));
        })
        .catch((error) => console.error("Error:", error));
    };

    getExchangeRate();
    const timer = setInterval(getExchangeRate, 60000);
    return () => clearInterval(timer);
  }, [dispatch, selectedCoin]);

  useEffect(() => {
    if (selectedCoin) {
      dispatch(setFee(selectedCoin.feePercentage));
      dispatch(calculateUSDP());
    }
  }, [selectedCoin, amount, exchangeRate, fee, dispatch]);

  const handleContinue = () => {
    dispatch(setNetwork(selectedCoin.network));
    dispatch(setIsLoading(true));
    const fetchDataUrl = `${endpoints.getGlobalWalletForUser}/${userMagicAddress}/${selectedCoin.nexusCurrencyId}`; // Global bridge
    fetchData(fetchDataUrl)
      .then((data) => {
        dispatch(setIsLoading(false));
        dispatch(setRingStatuses(true));
        dispatch(setDepositAddress(data.data));
        dispatch(updateStatus(2));
        dispatch(moveToPage(2));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.error("Error:", error);
      });
  };

  return (
    <div className="page2">
      {isLoading && <SingleTransactionStatus ringStatuses={ringStatuses} />}

      <div className="page2_content">
        <InputGroup />
        <ExchangeRate onClick={handleContinue} />
      </div>
    </div>
  );
};

export default Page2Global;
