import store from '../store/index';  // Make sure to import your Redux store
import { setTerms } from '../features/terms/termsSlice';

class Terms {
  static instance = null;
  
  static getInstance() {
    if (!Terms.instance) {
      Terms.instance = new Terms();
    }
    return Terms.instance;
  }

  setTerms(termsArray) {
    if (!Array.isArray(termsArray)) {
      console.error("Invalid terms data provided: expected an array");
      return;
    }

    // Transform array of objects into key-value pairs
    const termsObject = termsArray.reduce((acc, term) => {
      if (term.termKey && term.termValue) {
        acc[term.termKey] = term.termValue;
      }
      return acc;
    }, {});

    // Dispatch to Redux store
    store.dispatch(setTerms(termsObject));
  }

  get(key, defaultValue = key) {
    if (!key || typeof key !== "string") {
      console.warn("Invalid key provided to Terms.get()");
      return defaultValue;
    }

    const state = store.getState();
    const value = state.terms.terms[key];
    
    if (value === undefined) {
      console.warn(`Term key "${key}" not found`);
      return defaultValue;
    }

    return value;
  }

  isLoaded() {
    const state = store.getState();
    return state.terms.isLoaded;
  }
}

export const TermsUtil = Terms.getInstance();
