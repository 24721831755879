// src/components/PageBox.jsx
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetExchange } from '../features/exchange/exchangeSlice';
import { decrementStatus, handleBackClick, moveToPage } from '../features/navigation/navigationSlice';
import { updateStatus } from '../features/transaction/transactionSlice';
import Footer from './Footer';
import Header from './Header';
import InnerPagesComponents from './InnerPagesComponents';

const PageBox = () => {
  // useRenderCount("PageBox");
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const componentKey = useSelector((state) => state.navigation.componentKey);
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);

  const onBackClick = useCallback(() => {
    // Reset exchange values when navigating back to Page2 from Page3 or Page1 from Page2
    if (componentKey === 1 || componentKey === 2) {
      dispatch(resetExchange());
    }

    if (componentKey > 1) {
      dispatch(decrementStatus());
      dispatch(updateStatus(componentKey - 1));
      dispatch(moveToPage(componentKey - 1));
    } else {
      dispatch(handleBackClick());
    }
  }, [componentKey, dispatch]);

  const headerProps = useMemo(() => ({
    index: componentKey,
    onBackClick,
    decrementStatus: () => dispatch(decrementStatus()),
  }), [componentKey, onBackClick, dispatch]);

  return (
    <div  className={`page-box ${theme}`} data-is-allowed-domain={isAllowedDomain}>
      <Header {...headerProps} />
      <div className="content">
        <InnerPagesComponents />
      </div>
      <Footer />
    </div>
  );
};

export default PageBox;
