// src/features/exchange/exchangeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const exchangeSlice = createSlice({
  name: "exchange",
  initialState: {
    selectedCoin: null,
    amount: 0,
    exchangeRate: 1,
    fee: 0,
    usdpAmount: 0,
  },
  reducers: {
    setSelectedCoin: (state, action) => {
      state.selectedCoin = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setExchangeRate: (state, action) => {
      state.exchangeRate = action.payload;
    },
    setFee: (state, action) => {
      state.fee = action.payload;
    },
    calculateUSDP: (state) => {
      state.usdpAmount =
        state.amount * state.exchangeRate * (1 - state.fee / 100);
    },
    resetExchange: (state) => {
      state.amount = 0;
      state.fee = 0;
      state.usdpAmount = 0;
    },
  },
});

export const {
  setSelectedCoin,
  setAmount,
  setExchangeRate,
  setFee,
  calculateUSDP,
  resetExchange,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
