// src/components/Page4.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StatusBar from "./StatusBar";
import { setWsData } from "../features/transaction/transactionSlice";
import { selectTerms } from "../features/terms/termsSlice";
const Page4 = () => {
  const wsData = useSelector((state) => state.transaction.wsData);
  const transactionStatuses = useSelector(
    (state) => state.transactionStatus.statuses
  );
  const [ringStatuses, setRingStatuses] = useState([false, false, false]);
  const [amount, setAmount] = useState(null);
  const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
  const status = useSelector((state) => state.transaction.status);
  const searchParams = new URLSearchParams(window.location.search);
  const userWallet = searchParams.get("wa");
  const dispatch = useDispatch();
  const terms = useSelector(selectTerms);

  // Fake WebSocket data for testing with a timer
  //   useEffect(() => {
  //   const timer1 = setTimeout(() => {
  //     const fakeWsData1 = JSON.stringify({
  //         walletAddress: userWallet,
  //         timestamp: new Date().toISOString(),
  //         status: 'funds_sent_to_user',
  //         amount: '100.00 USDT'
  //     });

  //     dispatch(setWsData(fakeWsData1));

  //     const timer2 = setTimeout(() => {
  //       const fakeWsData2 = JSON.stringify({
  //           walletAddress: userWallet,
  //           timestamp: new Date().toISOString(),
  //           status: 'funds_received_by_user',
  //           amount: '100.00 USDT'
  //       });

  //       dispatch(setWsData(fakeWsData2));
  //     }, 3000); // Additional 3 seconds

  //     return () => clearTimeout(timer2);
  //   }, 3000); // 3 seconds

  //   return () => {
  //     clearTimeout(timer1);
  //     dispatch(setWsData(null));
  //   };
  // }, [dispatch, userWallet]);
  // End of fake WebSocket data

  useEffect(() => {
    if (wsData) {
      const messageData = JSON.parse(wsData);
      console.log("Message data:", messageData);

      if (!userWallet || userWallet !== messageData.walletAddress) {
        console.log("User wallet address does not match");
        return;
      }

      const newStatus = messageData.status.toLowerCase();

      setRingStatuses((prevStatuses) => {
        if (prevStatuses[2]) {
          // If the transaction is already completed, do not update further
          return prevStatuses;
        }

        switch (newStatus) {
          case "funds_received":
            return [true, false, false];
          case "funds_sent_to_user":
            return [true, true, false];
          case "funds_received_by_user":
            return [true, true, true];
          default:
            return prevStatuses;
        }
      });

      if (newStatus !== "funds_received") {
        setAmount(messageData.amount);
      }

      if (newStatus === "funds_received_by_user") {
        setIsTransactionCompleted(true);
      }
    }
  }, [wsData, userWallet, transactionStatuses]);

  return (
    <div className="page4">
      <StatusBar status={status} />
      <TransactionStatus ringStatuses={ringStatuses} />
      <AmountSent amount={amount} />
      <button
        className={`button green ${!isTransactionCompleted ? "disabled" : ""}`}
        disabled={!isTransactionCompleted}
        onClick={() => window.parent.postMessage("closeIframe", "*")}
      >
        <span>
          {!isTransactionCompleted
            ? /* "Transaction In Progress" */ terms.page4_continue_button_loading
            : /* "Transaction was successful" */ terms.page4_continue_button
            }
        </span>
      </button>
    </div>
  );
};

const TransactionStatus = ({ ringStatuses }) => {
  const terms = useSelector(selectTerms);
  /* const texts = ["Transaction arrived", "Exchanging", "Transaction complete"]; */
  const texts = [
    terms.page4_transaction_arrived,
    terms.page4_exchanging,
    terms.page4_transaction_complete,
  ];
  return (
    <div className="container">
      {ringStatuses.map((ringStatus, i) => (
        <div key={i} className="item">
          <div className="top">
            <div className={`ring ${ringStatus ? "full" : ""}`}>
              <svg height="200" width="200">
                <circle
                  className={`circle ${ringStatus ? "" : "loading"}`}
                  cx="100"
                  cy="100"
                  r="40"
                  stroke="#fe9725"
                  strokeWidth="6"
                  fillOpacity="0"
                />
              </svg>
              <div className="image" />
            </div>
          </div>
          <div className="bottom">
            <div className="text">{texts[i]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const AmountSent = ({ amount }) => {
  const terms = useSelector(selectTerms);
  return (
    <div className="amount-sent-container">
      <span className="usdp-text">
        {/* Amount was sent to Your wallet: */}
        {terms.page4_amount_sent}:
        </span>
      <div className="usdp-info">
        {amount && (
          <>
            <div className="usdp-icon" />
            <span className="usdp-amount">{amount}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Page4;
