// src/App.js
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import PageBox from "./components/PageBox";
import SingleTransactionStatus from "./components/SingleTransactionStatus";
import {
  resetHostDomain,
  setHostDomain,
  setUserMagicAddress,
} from "./features/host/hostSlice";
import { selectTermsLoaded } from "./features/terms/termsSlice";
import { getSearchParams } from "./utils/helpers";
import { getLanguageTerms, processLanguageTerms } from "./utils/languageUtils";
import { postMessageListener } from "./utils/postMessageListener";
import { TermsUtil } from "./utils/terms";
import { useFetchCoins } from "./utils/useFetchCoins";
import useWebSocket from "./utils/useWebSocket";
import { wsEndpoints } from "./utils/wsUrls";

function App() {
  const dispatch = useDispatch();
  const [wsEndpoint, setWsEndpoint] = useState(wsEndpoints.production);
  const { connect, disconnect } = useWebSocket(wsEndpoint);
  useFetchCoins();
  const loaded = useRef(null);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);
  const termsLoaded = useSelector(selectTermsLoaded);

  const updateLanguageCallback = async (language) => {
    // Handle language update
    // console.log("Language updated to:", language);
    const rawData = await getLanguageTerms(language);
    const newTerms = await processLanguageTerms(rawData, language);
    if (newTerms) {
      TermsUtil.setTerms(newTerms);
      // console.log("Terms updated for language:", language);
    } else {
      console.warn("Failed to fetch new terms for language:", language);
      // then make a default language call with 'en'
      const defaultData = await getLanguageTerms("en");
      const defaultTerms = await processLanguageTerms(defaultData, "en");
      if (defaultTerms) {
        TermsUtil.setTerms(defaultTerms);
        // console.log("Default terms updated for language: en");
      } else {
        console.warn("Failed to fetch default terms for language: en");
      }
    }
  };

  useEffect(() => {
    console.log("App mounted");
    if (loaded.current) return;
    console.log("Loaded.current", loaded.current);
    loaded.current = true;

    try {
      // Initialize the language listener with the callback
      if (window.self !== window.top) {
        postMessageListener(updateLanguageCallback);
      } else {
        updateLanguageCallback("en");
      }
    } catch (error) {
      console.error("Error initializing postMessageListener:", error);
    }

    const params = getSearchParams();
    const allowedOrigins = ["localhost:3002", "coinsexchange.com", "orkon2.loca.lt"];
    console.log("Received params from URL:", params);

    if (params.appDomain && allowedOrigins.includes(params.appDomain)) {
      console.log("Params from allowed origin:", params.appDomain);
      dispatch(setHostDomain(params.appDomain));
      if (params.userMagicAddress) {
        dispatch(setUserMagicAddress(params.userMagicAddress));
      }
      console.log("Received appDomain:", params.appDomain);
      console.log("Received userMagicAddress:", params.userMagicAddress);

      setWsEndpoint(
        params.appDomain === "localhost:3002" ||
          params.appDomain === "coinsexchange.com" ||
          params.appDomain === "orkon2.loca.lt"
          ? wsEndpoints.globalProduction
          : wsEndpoints.production
      );
    }

    return () => {
      dispatch(resetHostDomain());
      disconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!termsLoaded) {
      const timer = setTimeout(() => {
        updateLanguageCallback("en");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [termsLoaded, updateLanguageCallback]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      connect();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  switch (window.self === window.top) {
    case true:
      return (
        <div className="App">
          <PageBox />
        </div>
      );
    case false:
      if (termsLoaded) {
        return (
          <div className="App">
            <PageBox />
          </div>
        );
      } else {
        return (
          <div className="App">
            <SingleTransactionStatus ringStatuses={ringStatuses} />
          </div>
        );
      }
    default:
      return null;
  }
}

export default App;
