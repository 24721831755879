// src/utils/fetchUrls.js
const baseURL = "https://b.playblock.io";
const globalBaseURL = "https://b.coinsexchange.com";

export const endpoints = {
  getExchangeRate: `${baseURL}/getexchangerate`,
  getCoinsList: `${baseURL}/getcoinslist`,
  getWalletForUser: `${baseURL}/getWalletForUser`,
  getGlobalWalletForUser: `${globalBaseURL}/getGlobalWalletForUser`,
};
