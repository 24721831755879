// features/iframe/iframeSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const iframeSlice = createSlice({
  name: "iframe",
  initialState: {
    src: "",
    type: "",
    visible: false,
  },
  reducers: {
    set_iframe_popup: (state, action) => {
      state.src = action.payload.src;
      state.type = action.payload.type;
      state.visible = true;
    },
    hide_iframe_popup: (state) => {
      state.visible = false;
      state.src = "";
      state.type = "";
    },
  },
});

export const { set_iframe_popup, hide_iframe_popup } = iframeSlice.actions;

export default iframeSlice.reducer;
